@font-face {
font-family: '__proximaFont_8dea09';
src: url(/_next/static/media/5bc35956b6aaec48.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__proximaFont_8dea09';
src: url(/_next/static/media/f72ddc4266d0d2ba.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__proximaFont_8dea09';
src: url(/_next/static/media/f68460597dbfd4ac.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__proximaFont_8dea09';
src: url(/_next/static/media/ba79ba900f883b7a.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__proximaFont_8dea09';
src: url(/_next/static/media/4ce555f565b723fa.p.otf) format('opentype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__proximaFont_8dea09';
src: url(/_next/static/media/85e2ef83be05cb2d.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__proximaFont_8dea09';
src: url(/_next/static/media/9c939eba328264d0.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__proximaFont_8dea09';
src: url(/_next/static/media/351db43edbd78dbf.p.otf) format('opentype');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__proximaFont_8dea09';
src: url(/_next/static/media/0a447f1d8c7c4fab.p.otf) format('opentype');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__proximaFont_Fallback_8dea09';src: local("Arial");ascent-override: 79.64%;descent-override: 21.17%;line-gap-override: 0.00%;size-adjust: 99.19%
}.__className_8dea09 {font-family: '__proximaFont_8dea09', '__proximaFont_Fallback_8dea09'
}.__variable_8dea09 {--font-proxima: '__proximaFont_8dea09', '__proximaFont_Fallback_8dea09'
}

